import React from "react";
import Layout from "../layouts/layout";
import Header from "../common/header";
import Footer from "../common/footer";
import styled from "styled-components";
import { Breakpoints, Spacing } from "../../styles/styleConstants";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import DividerLinesImage from "../../images/home/divider-lines.png";
import { QUERY_PARAMS } from "../../utils/commonConst";
import Mission from "../Mission";
import FoundersStory from "../FoundersStory";
import TeamView from "../team/TeamView";
import { parseReqParams } from "../../utils/commonUtil";
import Hero from "./hero";

export default function AboutUs(props) {
  const referralCode = parseReqParams(QUERY_PARAMS.REF);
  const signupCode = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);

  return (
    <Layout includeHeader={false} includeFooter={false}>
      {/* {process.env.GATSBY_SHOW_BANNER === "true" && <Banner />} */}
      <HeroWrapper>
        <Header />
        <MissionWraper>
          <Mission />
        </MissionWraper>
      </HeroWrapper>
      {/* <MissionWraper>
        <Mission />
      </MissionWraper> */}
      <DividerLinesWrapper>
        <img src={DividerLinesImage} alt="------" />
      </DividerLinesWrapper>
      <TeamViewWrapper>
        <TeamView />
      </TeamViewWrapper>
      <DividerLinesWrapper>
        <img src={DividerLinesImage} alt="------" />
      </DividerLinesWrapper>
      <FoundersStoryWrapper>
        <FoundersStory signupCode={signupCode} />
      </FoundersStoryWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const DividerLinesWrapper = styled.div`
  margin-bottom: ${Spacing.xxxxl};

  img {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: ${Spacing.xl};
  }
`;

const TeamViewWrapper = styled.div`
  margin: 0% 5%;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 0px 0px;
  }
`;

const FoundersStoryWrapper = styled.div`
  margin: 0% 5%;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 0px 0px;
  }
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const MissionWraper = styled.div`
  margin: 96px 25%;

  @media (max-width: ${Breakpoints.sm}) {
    margin: 40px 0px 48px;
  }
`;
