import React from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "../styles/styleConstants";
import { HEADER_LEVEL } from "../utils/commonConst";
import HeaderText from "./common/HeaderText";
import SemiBoldTitle from "./common/SemiBoldTitle";

export default function Mission(props) {
  return (
    <MissionWrapper>
      <MissionTag>Mission</MissionTag>
      <MissionHeader level={HEADER_LEVEL.LEVEL_1}>
        <SemiBoldTitle>
          Give everyone in the world the capability to optimize their financial
          life.
        </SemiBoldTitle>
      </MissionHeader>
      <MissionSubHeader>
        Personalized financial advice used to be reserved only for the super
        wealthy. We're on a mission to change that.
      </MissionSubHeader>
    </MissionWrapper>
  );
}

const MissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${Breakpoints.sm}) {
    padding: 0px 24px;
  }
`;

const MissionTag = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.blue};
  margin-bottom: 16px;
`;

const MissionHeader = styled.h1`
  // font-style: normal;
  // font-weight: 700;
  // font-size: 44px;
  // line-height: 140%;
  // color: ${Colors.almostBlack};
  // @media (max-width: ${Breakpoints.sm}) {
  //   line-height: 120%;
  // }
`;

const MissionSubHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};
`;
