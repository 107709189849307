import React from "react";
import styled from "styled-components";
import TeamMemberCard from "./TeamCard";

import { TeamProfiles } from "../../data/homeData";
import { Breakpoints, Colors } from "../../styles/styleConstants";
import HeaderText from "../common/HeaderText";
import { HEADER_LEVEL } from "../../utils/commonConst";

export default function TeamView(props) {
  const { style } = props ?? {};

  const teamProfileRender = TeamProfiles.map((teamProfile) => {
    return <TeamMemberCard teamProfile={teamProfile} />;
  });

  return (
    <TeamViewWrapper style={style}>
      <TeamViewBody>
        <TeamTitle level={HEADER_LEVEL.LEVEL_1}>
          Our amazing team of do-ers
        </TeamTitle>
        <TeamMembersProfileWrapper>
          <TeamMembersProfile>{teamProfileRender}</TeamMembersProfile>
        </TeamMembersProfileWrapper>
      </TeamViewBody>
    </TeamViewWrapper>
  );
}

const TeamViewWrapper = styled.div`
  margin: 24px 0px 80px;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 24px;
  }
`;

const TeamViewBody = styled.div`
  @media (min-width: ${Breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const TeamTitle = styled(HeaderText)`
  // font-style: normal;
  // font-weight: 700;
  // font-size: 44px;
  // line-height: 120%;
  // margin-bottom: 32px;
  // color: ${Colors.almostBlack};
  // @media (max-width: ${Breakpoints.sm}) {
  //   font-weight: 700;
  //   font-size: 32px;
  //   line-height: 38.4px;
  // }
`;

const TeamMembersProfileWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const TeamMembersProfile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
  @media (max-width: ${Breakpoints.sm}) {
    gap: 12px;
    padding: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
  }
`;
