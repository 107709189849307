import React from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "../styles/styleConstants";
import { HEADER_LEVEL } from "../utils/commonConst";
import { getAppUrlByModule, navigateTo } from "../utils/commonUtil";
import CtaButton from "./common/CtaButton";
import HeaderText from "./common/HeaderText";
import Image from "./common/Image";
import WaitlistModalCta from "./common/WaitlistModalCta";

export default function FoundersStory(props) {
  const { signupCode } = props ?? {};
  return (
    <FoundersStoryWrapper>
      <FoundersStoryHeader level={HEADER_LEVEL.LEVEL_1}>
        How it all began
      </FoundersStoryHeader>
      <FoundersStoryContent>
        <FoundersStoryText>
          <div>
            Growing up,{" "}
            <FounderNameStyle>
              <a
                href="https://www.linkedin.com/in/jessicachenriolfi/"
                target="_blank"
              >
                Jess
              </a>
            </FounderNameStyle>{" "}
            was lucky to have a mom who worked at the FDIC, someone she could
            turn to whenever she had any question about personal finances.
            Hoping to pay it forward, Jess also went on to work in finance at
            companies like Robinhood, Wise, and Earnin. And she realized that
            while fintech has made a ton of progress, there's still nothing out
            there that could quite beat her mom: someone who takes the time to
            understand, and who cuts through all the noise and gives spot-on
            answers and advice at the exact right time.
          </div>
          <br />
          <div>
            <FounderNameStyle>
              <a href="https://www.linkedin.com/in/cgoodmac/" target="_blank">
                Chris
              </a>
            </FounderNameStyle>{" "}
            was raised by a single mother, and making sure the family could
            “make it” was always top of mind for him. He got a masters in
            finance from Oxford, worked for a hedge fund, and was the second
            employee at Justworks, the multi-billion dollar payroll company.
            There, he saw people leaving tons of free money on the table because
            employer benefits are unnecessarily complex.{" "}
          </div>
          <br />
          <div>
            They teamed up to help people find every cent and, ultimately, to
            give people the thing that matters most: peace of mind.
          </div>
        </FoundersStoryText>
        <FoundersPhoto>
          <Image
            src="home/team/fndrs.png"
            style={{
              margin: "0px 0px 0px 0px",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
          />
        </FoundersPhoto>
      </FoundersStoryContent>
      <CtaWrapper>
        {/* <WaitlistModalCta signupCode={signupCode} /> */}
        <CtaButton
          onClick={(evt) => {
            // _onSignup();
            navigateTo(getAppUrlByModule());
            evt.preventDefault();
            evt.stopPropagation();
          }}
          ctaType="primary"
        >
          Get started
        </CtaButton>
      </CtaWrapper>
    </FoundersStoryWrapper>
  );
}

const FoundersStoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0% 10%;
  gap: 48px;

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0px 24px;
    margin-bottom: 48px;
    gap: 24px;
    align-items: flex-start;
  }
`;

const FoundersStoryHeader = styled(HeaderText)`
  //   font-style: normal;
  //   font-weight: 700;
  //   font-size: 44px;
  //   line-height: 120%;
  //   color: ${Colors.almostBlack};
  //   @media (max-width: ${Breakpoints.sm}) {
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 32px;
  //     line-height: 38.4px;
  //     color: ${Colors.almostBlack};
  //   }
`;

const FoundersStoryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column-reverse;
  }
`;

const FoundersStoryText = styled.div`
  flex-basis: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: ${Colors.almostBlack};
`;

const FounderNameStyle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.blue};
`;
const FoundersPhoto = styled.div`
  flex-basis: 50%;
  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`;

const CtaWrapper = styled.div`
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 12px;
  }
`;
