import React from "react";
import styled from "styled-components";
import { Breakpoints, Spacing, Colors } from "../../styles/styleConstants";
import Image from "../common/Image";
import LogoBar from "../common/logoBar";

export default function TeamMemberCard(props) {
  const { teamProfile, style } = props ?? {};
  const { name, photoUrl, title, oneLiner, companyImages, emoji, linkedInUrl } =
    teamProfile ?? {};
  const foundersCompaniesList = companyImages.map((imageInfo, index) => {
    const { path, width, height } = imageInfo ?? {};
    return (
      <Image
        className="LogoBarImage"
        key={`company-logo-${index}`}
        src={path}
        style={{ width: width, height: height }}
        imgStyle={{ objectFit: "contain" }}
      />
    );
  });
  return (
    <TeamMemberCardWrapper style={style}>
      <TeamMemberLinkedInLink href={linkedInUrl} target="_blank">
        <TeamMemberCardBody>
          <TeamMemberPhotoWrapper>
            <Image
              src={photoUrl}
              imgStyle={{
                objectFit: "contain",
              }}
              style={{ width: "100px", height: "100px" }}
            />
            <TeamMemberEmoji>{emoji}</TeamMemberEmoji>
          </TeamMemberPhotoWrapper>
          <TeamMemberName>{name}</TeamMemberName>
          <TeamMemberTitle>{title}</TeamMemberTitle>
          <TeamMemberOneLiner>{oneLiner}</TeamMemberOneLiner>
        </TeamMemberCardBody>
        <TeamMemberCardFooter>{foundersCompaniesList}</TeamMemberCardFooter>
      </TeamMemberLinkedInLink>
    </TeamMemberCardWrapper>
  );
}

const TeamMemberCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${Colors.almostBlack};
  cursor: pointer;

  :hover {
    background: ${Colors.neutralLight};
  }

  @media (max-width: ${Breakpoints.sm}) {
    padding: 12px;
  }
`;

const TeamMemberLinkedInLink = styled.a`
  :hover {
    text-decoration: none;
  }
`;
const TeamMemberCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 300px;
  height: 300px;
  @media (max-width: ${Breakpoints.sm}) {
    width: 280px;
    height: 280px;
  }
`;

const TeamMemberPhotoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const TeamMemberName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};
`;

const TeamMemberTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};
`;

const TeamMemberEmoji = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  position: relative;
  left: -15px;
`;
const TeamMemberOneLiner = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.almostBlack};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: "row";
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
  @media (min-width: ${Breakpoints.md}) {
    flex-wrap: nowrap;
  }
`;

const TeamMemberCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  @media (max-width: ${Breakpoints.sm}) {
    width: 280px;
  }
`;
