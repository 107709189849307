import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WaitlistModalCta from "../common/WaitlistModalCta";
import Image from "../common/Image";
import styled from "styled-components";
// import SuccessCheck from "../../images/home/success_check.png";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
import { HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "../common/HeaderText";
import HelperText from "../common/HelperText";
import {
  getAppUrlByModule,
  navigateTo,
  scrollTo,
} from "../../utils/commonUtil";
import CtaButton from "../common/CtaButton";

export default function Hero(props) {
  const { signupCode } = props ?? {};
  const [paramString, setParamString] = useState("");
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <HeroWrapper>
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <HeaderText level={HEADER_LEVEL.LEVEL_1}>
              Never worry about money again.
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_6}
              style={{ marginTop: "24px" }}
            >
              <span style={{ fontWeight: 400 }}>
                Add an{" "}
                <span
                  style={{ color: "#000", cursor: "pointer" }}
                  onClick={() => scrollTo("#faq")}
                >
                  <u>average $1.5m in net worth</u>
                </span>{" "}
                back in your pocket with expert advice from our team of
                Certified Financial Planners.
              </span>
            </HeaderText>
            {/* <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Fully personalized</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Reviewed by a (human) expert</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>100% free</ValuePropText>
            </ValuePropTextWrapper> */}
            <CtaWrapper>
              {/* <WaitlistModalCta signupCode={signupCode} /> */}
              <CtaButton
                onClick={(evt) => {
                  // _onSignup();
                  navigateTo(getAppUrlByModule());
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="primary"
              >
                Get started
              </CtaButton>
            </CtaWrapper>
            <HelperText style={{ display: "flex", alignItems: "center" }}>
              <Image
                src="v2.0/shield.png"
                style={{ width: "24px", height: "24px", marginRight: "5px" }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              45-day 100% money-back guarantee
            </HelperText>
          </Col>
          <Col md={6} lg={7}>
            <Image className="HeroImage" src="v2.0/uprise-plan-sample.gif" />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  margin-top: 24px;
  .HeroImage {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 40px;
  }
`;

// const NavigateToCostFaq = styled.a`
//   position: relative;
//   font-weight: normal;
//   color: ${Colors.almostBlack};
//   background: linear-gradient(
//       90deg,
//       rgba(49, 122, 249, 1) 0%,
//       rgba(5, 232, 225, 1) 100%
//     )
//     repeat-x;
//   background-size: 330px 6px;
//   background-position: 0px 0.99em;

//   :hover {
//     color: ${Colors.blue};
//     cursor: pointer;
//     text-decoration: none;
//   }
// `;

const CtaWrapper = styled.div`
  margin: 40px 0px 16px 0px;
`;

// const ValuePropTextWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-bottom: 8px;
// `;

// const ValuePropIcon = styled.img`
//   width: 24px;
//   height: 24px;
// `;

// const ValuePropText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 30px;

//   color: #212529;

//   margin: 0px 16px;
// `;
